export const buttonConfig = {
  baseStyle: {
    borderRadius: 'button',
  },
  sizes: {
    md: {
      borderWidth: 2,
      fontSize: 'lg',
    },
  },
  variants: {
    solid: {
      bg: 'synchrony.teal',
      borderColor: 'synchrony.teal',
      color: 'white',
      _hover: {
        bg: 'teal.700',
        borderColor: 'teal.700',
        _disabled: {
          opacity: 0.5,
          bg: 'teal.700',
          borderColor: 'teal.700',
        },
      },
      _active: {
        bg: 'teal.800',
        borderColor: 'teal.800',
      },
      _disabled: {
        opacity: 0.5,
      },
    },
    outline: {
      bg: 'transparent',
      color: 'synchrony.teal',
      borderColor: 'synchrony.teal',
    },
    'outline-white': {
      bg: 'transparent',
      color: 'white',
      borderColor: 'white',
      _hover: {
        bg: 'whiteAlpha.400',
      },
      _active: {
        bg: 'whiteAlpha.500',
      },
    },
    card: {
      bg: 'white',
      color: 'gray.700',
      borderColor: 'white',
      borderRadius: 8,
      width: 185,
      fontSize: 'sm',
      fontWeight: 'extrabold',
    },
  },
  defaultProps: {
    size: 'md',
    variant: 'solid',
  },
};
