import { Global } from '@emotion/react';

export const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'Calibri';
        src: url('/fonts/Calibri-Regular.ttf');
        font-style: normal;
        font-weight: 400;
      }

      @font-face {
        font-family: 'Calibri';
        src: url('/fonts/Calibri-Bold.ttf');
        font-style: normal;
        font-weight: 700;
      }
    `}
  />
);
