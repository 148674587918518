/* eslint-disable no-restricted-globals */
import { ApolloClient, InMemoryCache, createHttpLink, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { SentryLink } from 'apollo-link-sentry';
import fetch from 'cross-fetch';

import { LOGIN_TOKEN_KEY } from '../constants';

import { cookies } from './cookies';

export function createApolloClient(ctx?: Record<string, any>) {
  // Apollo needs an absolute URL when in SSR, so determine host
  let host: string;
  let protocol: string;
  let hostUrl = process.env.API_URL;

  if (ctx) {
    host = ctx?.req.headers['x-forwarded-host'];
    protocol = ctx?.req.headers['x-forwarded-proto'] || 'http';
    hostUrl = `${protocol}://${host}`;
  } else if (typeof location !== 'undefined') {
    host = location.host;
    protocol = location.protocol;
    hostUrl = `${protocol}//${host}`;
  }

  const uri = `${hostUrl}/api/graphql`;

  const httpLink = createHttpLink({
    uri,
    fetch,
  });

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = cookies().get(LOGIN_TOKEN_KEY);
    // const token = localStorage.getItem(LOGIN_TOKEN_KEY);
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  const sentryLink = new SentryLink({
    attachBreadcrumbs: {
      includeQuery: true,
      includeVariables: true,
      includeFetchResult: true,
      includeError: true,
    },
  });

  const client = new ApolloClient({
    link: ApolloLink.from([authLink, sentryLink, httpLink]),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            rx: {
              read(_, { args, toReference }) {
                return toReference({
                  __typename: 'Rx',
                  id: args?.id,
                });
              },
            },
            customer: {
              read(_, { args, toReference }) {
                return toReference({
                  __typename: 'Customer',
                  id: args?.id,
                });
              },
            },
          },
        },
      },
    }),
  });

  return client;
}
