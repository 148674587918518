export const inputConfig = {
  baseStyle: {},
  sizes: {},
  variants: {
    outline: {
      field: {
        _disabled: {
          opacity: 0.7,
          bg: 'gray.100',
        },
      },
    },
  },
  defaultProps: {
    variant: 'outline',
  },
};
