import { Box, Flex, Text } from '@chakra-ui/react';
import Link from 'next/link';

/** Shows information on preview deployment when it is one */
export const PreviewDeployBar = () => {
  const isPr = process.env.IS_PULL_REQUEST;
  const branch = process.env.RENDER_GIT_BRANCH;
  const commit = process.env.RENDER_GIT_COMMIT;
  const slug = process.env.RENDER_GIT_REPO_SLUG;
  const externalUrl = process.env.RENDER_EXTERNAL_URL;

  if (isPr === 'true') {
    return (
      <Flex
        backgroundColor="gray.700"
        flexDirection={['column', 'row']}
        justify="space-between"
        p={4}
      >
        <Box>
          <Text as="span" color="white" fontWeight="bold">
            Preview Deploy:{' '}
          </Text>
          <Link href={`${externalUrl}`} passHref={true}>
            <Text as="span" textStyle="link">
              {externalUrl}
            </Text>
          </Link>
        </Box>
        <Box>
          <Text as="span" color="white" fontWeight="bold">
            Branch:{' '}
          </Text>
          <Link href={`https://github.com/${slug}/tree/${branch}`} passHref={true}>
            <Text as="span" textStyle="link">
              {branch}
            </Text>
          </Link>
        </Box>
        <Box>
          <Text as="span" color="white" fontWeight="bold">
            Commit:{' '}
          </Text>
          <Link href={`https://github.com/${slug}/commit/${commit}`} passHref={true}>
            <Text as="span" textStyle="link">
              {commit}
            </Text>
          </Link>
        </Box>
      </Flex>
    );
  }

  return null;
};
