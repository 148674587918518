import * as Sentry from '@sentry/nextjs';
import type { AppProps } from 'next/app';
import React, { useEffect } from 'react';

import { AllProviders } from '../components/generic/AllProviders';
import { Fonts } from '../components/generic/Fonts';
import { PreviewDeployBar } from '../components/generic/PreviewDeployBar';

function App({ pageProps, Component }: AppProps) {
  const user = pageProps.user;

  useEffect(() => {
    if (user) {
      Sentry.setUser(user);
    }
  }, [user]);

  return (
    <AllProviders>
      <Fonts />
      <PreviewDeployBar />
      <Component {...pageProps} />
    </AllProviders>
  );
}

export default App;
